<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/deviceList' }">报废设备</el-breadcrumb-item>
        <el-breadcrumb-item>设备详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <div class="title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称：" prop="name">{{ form.deviceName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料编码：" prop="name">{{ form.materialsCode }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备型号：" prop="name">{{ form.model }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：" prop="name">{{ form.specification }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生产厂家：" prop="name">{{ form.factory }}</el-form-item>
          </el-col>
        </el-row>
        <div class="title">使用信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备分类：" prop="name">{{ form.typeName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备位置：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="name">{{ form.departmentName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人：" prop="name">{{ form.staffName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备状态：" prop="name">{{ form.statusName }}</el-form-item>
          </el-col>
        </el-row>
        <div class="title">检查内容</div>
        <el-form-item
          v-for="item in form.checkContents"
          :key="item.id"
          label="检查内容："
          prop="name"
        >{{ item.content }}</el-form-item>

        <div class="title">设备图片</div>
        <div class="img-box">
          <el-image 
            class="images"
            v-for="item in form.deviceArchivesImages"
            :key="item.id"
            :src="item.url"
            :preview-src-list="[item.url]">
          </el-image>
          <!-- <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" /> -->
        </div>
        <!-- files  -->
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false
    };
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post("deviceDetail", {
        id: this.row.id,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
			this.$router.push('/deviceList')
		} else {
      this.row = this.$route.params.row
    }
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.mains {
  height: 80%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
.title {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  padding-left: 10px;
  margin-bottom: 20px;
}
</style>
<style lang="less">
</style>
